<template>
  <div class="row mb-3" v-if="bind.boolCarregado">
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md12 xl6>
        <v-card class="mb-10" hover append>
          <v-card-title>
            <div class="col-12 pl-0 pb-0 form-title">Funcionário</div>
          </v-card-title>

          <div class="row pl-3 pt-0 pr-3 pb-0">

            <!-- <ed-input-select
              v-if="$root.$session.filiais.length>1"
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0 pb-0"
              v-model="formData.filiais"
              label="Filial"
              name="filiais"
              rules="required"
              :items="$root.$session.filiais"
              multiple
            /> -->


            <ed-input-auto-complete
              class="col-xs-12 pl-4 col-sm-12 col-md-12  col-lg-12 col-xl-12 pt-0"
              v-model="formData.intFuncionarioId"
              label="Funcionário"
              name="intFuncionarioId"
              route="Cadastro/Funcionario"
              placeholder="Buscar por CPF ou nome do funcionário"
              :initializeItems="formData.intFuncionarioId ? true : false"
              @selected="setFuncionario"
              :filters="{
                strOrderBy: 'strNome',
              }"
              rules="required"
            />
          </div>

          <div class="row pl-3 pr-3 pt-0 mt-0">
            <div class="col-12 pl-3 pb-3 pt-3 form-title">Informações da Consulta</div>

            <ed-input-select
              class="col-xs-12 pl-4  pr-4 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
              name="intParametroGrupoId"
              label="Tipo de Consulta"
              rules="required"
              v-model="formData.intParametroGrupoId"
              :items="bind.objGrupo.parametros"
              @selected="setGrupo"
              :disabled="!formData.intFuncionarioId ? true : false"
            />

            <ed-input-select
              v-if="formData.grupo"
              class="col-xs-12 pl-4  pr-4 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
              v-model="formData.intParametroSubGrupoId"
              name="intParametroSubGrupoId"
              rules="required"
              :label="formData.grupo.strValor.strLabel"
              :items="formData.grupo.parametros"
              @selected="setSubGrupo"
              :readonly="formData.intId > 0"
            />

            <ed-input-select
              v-if="
                formData.sub_grupo &&
                formData.sub_grupo.strValor &&
                formData.sub_grupo.strValor.meses &&
                formData.sub_grupo.strValor.meses.length
              "
              class="col-xs-12 pl-4  pr-4 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
              v-model="formData.intMeses"
              name="intMeses"
              label="Periodicidade do Exame"
              rules="required"
              :items="formData.sub_grupo.strValor.meses"
            />
          </div>
        </v-card> </v-flex
    ></v-layout>

    <ed-consulta
      v-if="boolExibirCadastro"
      :intFuncionarioId="formData.intFuncionarioId"
      :intParametroGrupoId="formData.intParametroGrupoId"
      :intParametroSubGrupoId="formData.intParametroSubGrupoId"
      :intMeses="formData.intMeses"
      :grupo="formData.grupo"
      :sub-grupo="formData.sub_grupo"
      :key="$root.$session.versao"
    />
  </div>
</template>

<script>
import {
  EdTable,
  EdInputAutoComplete,
  EdInputRadio,
  EdInputSelect,
  EdModal,
} from "@/components/common/form";
import EdConsulta from "@/components/consulta/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {
    EdTable,
    EdInputAutoComplete,
    EdModal,
    EdInputRadio,
    EdInputSelect,
    EdConsulta,
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  mounted() {
    if (this.empresaAtivo) {
      this.getCadastroGeral(this.empresaAtivo);
    } else if (this.$route.params.intCadastroGeralEmpresaId) {
      this.getCadastroGeral(this.$route.params.intCadastroGeralEmpresaId);
    }
    this.initialize();
  },
  created() {},
  data() {
    return {
      formData: {
        intFuncionarioId: this.$route.params.intFuncionarioId
          ? Number(this.$route.params.intFuncionarioId)
          : null,
        intParametroSubGrupoId: null,
        intParametroGrupoId: null,
        grupo: null,
        sub_grupo: null,
        filiais:[this.$root.$session.filial ? this.$root.$session.filial.id : 0]
      },
      bind: {
        objFuncionario: null,
        objGrupo: null,
        boolCarregado: false,
      },
    };
  },
  watch: {},
  computed: {
    empresaAtivo: {
      cache: false,
      get() {
        return this.$store.state.funcionario.intEmpresaAtivo;
      },
      set(value) {
        this.$store.dispatch("funcionario/setEmpresaAtivo", value);
      },
    },
    boolExibirCadastro() {
      if (this.formData.intFuncionarioId) {
        if (
          this.formData.filiais.length &&
          this.formData.intFuncionarioId &&
          this.formData.intParametroGrupoId &&
          this.formData.intParametroSubGrupoId
        ) {
          return true;
        }
      }

      return false;
    },
  },
  methods: {
    setFuncionario(objFuncionario) {
      this.bind.objFuncionario = objFuncionario;
    },

    setGrupo(objValue) {
      if (this.formData.intId) return;
      this.formData.grupo = objValue;
      this.formData.sub_grupo = null;
      this.formData.intParametroSubGrupoId = null;
    },
    setSubGrupo(objValue) {
      if (this.formData.intId) return;

      this.formData.sub_grupo = objValue;
    },

    initialize() {
      this.$root.$dialog.loading(true);

      Promise.all([
        this.$root.$request.get("Common/Parametro", {
          strChave: "consultaGrupo",
        }),
      ]).then(([objDados]) => {
        if (objDados.status == 200 && objDados.result) {
          this.bind.objGrupo = objDados.result;
        }

        this.$root.$dialog.loading(false);
        this.bind.boolCarregado = true;
      });
    },
  },
};
</script>
